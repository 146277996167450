<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Genesis Page</h1>
    <Hero heading="Power the Potential of Your Fully Electric Genesis" headlineLevel="h2" :subheading="true">
      <template #subheading>
        Unlock infinite possibilities charging on Electrify America’s Hyper-Fast charging network – compliments of
        Genesis.
        <ReferenceLink :num="1">See specific Promotion & Conditions for details and restrictions. </ReferenceLink>
      </template>
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Genesis/hero.png" />
          <img src="@/assets/images/Genesis/hero-mobile.png" alt="Electrify America charging stations at dusk"
        /></picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve With Electrify America" id="select-model" headlineLevel="h3">
      <p>
        The future of electric mobility can’t wait, and with Electrify America, it doesn’t have to. We’ve built a
        convenient and growing network of over 4,400 electric vehicle chargers coast-to-coast—along major highways and
        routes—to take electric vehicle (EV) drivers farther than ever before.
      </p>
      <PartnerDropdown id="genesis-select" v-model="modelChosen" :options="genesisDropdownOptions">
        <template #label>
          <div>
            <label for="genesis-select">Select your Genesis model</label>
          </div>
        </template>
      </PartnerDropdown>
      <p v-if="modelChosen === null">
        Genesis drivers can focus on the roads and possibilities ahead, leaving gas stations and MPG behind.
      </p>
      <section v-else>
        <p>
          <strong>{{ modelChosen }} </strong>
          <reference-link :num="2"
            >Available on new model year
            <span>{{ disclosureModel }}</span>
            vehicles purchased on or before February 28, 2025, from an authorized U.S.-based Genesis dealer (excluding
            dealers located in Alaska). Thirty-six (36) months of 30-minute complimentary DC fast charging sessions and
            60-minute Level 2 charging sessions begin upon vehicle purchase. Complimentary charging time does not
            include any idle fees and their associated or applicable taxes assessed at the charging station. You will be
            responsible for paying such fees through your account if you incur them during a charging session. There is
            a 60-minute waiting period between eligible charging sessions, and the promotion benefits will not apply to
            a charging session initiated within this period. Non-transferable. Not available for commercial use, such as
            ride-hailing and ridesharing. Requires Electrify America account, app, acceptance of
            <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>,
            <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>,
            <router-link class="link" :to="{ name: disclosureLink }">Promotion Terms and Conditions</router-link>,
            enrollment in the {{ chargingPlanText }} Plan, and $10 deposit to pre-fund idle fees, which may be
            automatically reloaded. See Terms of Use for details. Unused idle fee deposit will be refunded when account
            is closed. After benefits terminate or expire, your account will convert to a basic Electrify America “Pass”
            account. Your actual charging speed may vary.
            <span v-if="modelChosen.includes('2023')"
              >Frequent and consecutive charging can permanently decrease battery capacity, which may not be covered by
              your vehicle’s warranty.</span
            >
          </reference-link>
          models purchased on or before February 28, 2025, come with three years of complimentary 30-minute charging.
          For full program details, click
          <router-link class="link" :to="{ name: disclosureLink }">here</router-link>.
        </p>
      </section>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go With Freedom" headlineLevel="h3">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Genesis/go-with-freedom.png" />
          <img loading="lazy" src="@/assets/images/Genesis/go-with-freedom-mobile.png" alt=""
        /></picture>
      </template>
      <p v-if="modelChosen === null">
        Get going with Electrify America's Hyper-Fast charging network. Select your Genesis model above to learn about
        available benefits.
      </p>
      <section v-else>
        <p>
          Get going with three years of complimentary 30-minute charging sessions on Electrify America’s Hyper-Fast
          charging network. Just follow these simple steps:
        </p>
        <ul>
          <li>
            <strong>Step 1:</strong> Download the Electrify America app
            <ReferenceLink :num="3"
              >Your mobile carrier’s messaging, data, and other rates and fees will apply to your use of the Electrify
              America mobile application.</ReferenceLink
            >, create your account, and select “Set Up Public Charging.”
          </li>
          <li>
            <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
            <ul>
              <li>Or search for Premium Offer by vehicle information.</li>
              <li>
                Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
                enrollment code.”
              </li>
            </ul>
          </li>
          <li>
            <strong>Step 3:</strong>
            : Input your enrollment code, which you will receive from your Genesis dealer or select your
            <strong> {{ chargingPlanText }} Plan</strong>. Input your vehicle’s 17-digit VIN
            <reference-link :num="4"
              >The VIN can usually be found on the driver’s side dashboard, near or under the bottom portion of the
              windshield</reference-link
            >
            for verification.
          </li>
          <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap “Select this plan.”</li>
          <li>
            <strong>Step 5:</strong> Enter payment info and set up auto-reload.
            <ul>
              <li>
                You will need to enter a payment method
                <reference-link :num="5"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station, and the billed amount will be held in your
                  account to cover costs not covered by this plan, such as idle fees and applicable
                  taxes.</reference-link
                >
                to cover any incurred idle fees
                <reference-link :num="6"
                  >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                  will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                  that others can use the charger. You can be notified when your charging session is complete by text
                  message (your mobile carrier’s messaging, data and other rates may apply) or see it on the charger
                  screen.</reference-link
                >
                or applicable taxes.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call
          <strong>1-833-632-2778</strong>
          to contact Electrify America Customer Assistance, available 24/7.
        </p>
      </section>
      <router-link v-if="modelChosen !== null" class="button" :to="{ name: 'mobile-app-en' }"
        >Download the App</router-link
      >
      <a v-else class="button" href="#select-model">SELECT YOUR MODEL</a>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import ReferenceLink from '../../components/ReferenceLink/ReferenceLink.vue';

export default {
  components: {
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
    ReferenceLink,
  },
  metaInfo: {
    title: 'Genesis | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/genesis/' }],
  },
  data() {
    return {
      genesisDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2025 Genesis GV60', value: '2025 Genesis GV60' },
        { text: '2025 Genesis Electrified GV70', value: '2025 Genesis Electrified GV70' },
        { text: '2025 Genesis Electrified G80', value: '2025 Genesis Electrified G80' },
        { text: '2024 Genesis GV60', value: '2024 Genesis GV60' },
        { text: '2024 Genesis Electrified GV70', value: '2024 Genesis Electrified GV70' },
        { text: '2024 Genesis Electrified G80', value: '2024 Genesis Electrified G80' },
        { text: '2023 Genesis GV60', value: '2023 Genesis GV60' },
        { text: '2023 Genesis Electrified GV70', value: '2023 Genesis Electrified GV70' },
        { text: '2023 Genesis Electrified G80', value: '2023 Genesis Electrified G80' },
      ],
      modelChosen: null,
    };
  },
  computed: {
    modelNumber() {
      const n = this.modelChosen.split(' ');
      return n[n.length - 1];
    },
    modelYear() {
      return this.modelChosen?.substring(0, 4);
    },
    disclosureLink() {
      if (this.modelYear === '2025') return '2025-genesis-charging-plan-disclosure-en';
      switch (this.modelChosen) {
        case '2023 Genesis GV60':
          return '2023-gv60-charging-plan-disclosure-en';
        case '2024 Genesis Electrified G80':
          return '2024-electrified-g80-plan-disclosure-en';
        case '2023 Genesis Electrified G80':
          return '2023-electrified-g80-plan-disclosure-en';
        case '2024 Genesis GV60':
          return '2024-gv60-charging-plan-disclosure-en';
        case '2023 Genesis Electrified GV70':
          return '2023-electrified-gv70-plan-disclosure-en';
        case '2024 Genesis Electrified GV70':
        default:
          return '2024-gv70-charging-plan-disclosure-en';
      }
    },
    disclosureModel() {
      if (this.modelYear === '2025') return '2025 Genesis GV60, Electrified GV70, and Electriﬁed G80';
      if (this.modelChosen === '2024 Genesis GV60') return '2024 Genesis Electrified GV60';
      return this.modelChosen;
    },
    chargingPlanText() {
      if (this.modelYear === '2025') return '2025 Genesis Charging';
      if (this.modelNumber === 'GV60') return `${this.modelYear} Genesis GV60 Charging`;
      return this.modelChosen;
    },
  },
};
</script>
